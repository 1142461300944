/* eslint-disable camelcase */
import React from 'react';
import {
  Backdrop, Button, Card, CircularProgress, Divider, Typography, useTheme,
} from '@mui/material';
import { getFintoc } from '@fintoc/fintoc-js';
import BancameAlert from '../Alert/index';
import { getFintocSubscription, postFintocSubscription } from '../../queries/account';
import useStyles from './styles';
import analytics from '../../utils/analytics';
import fintocLogo from '../../assets/fintoc.svg';

type FintocEventGTMLabels = {
  [key:string]:string,
  on_available_institutions: string,
  on_authentication_form: string,
  creating_link: string,
  selecting_account: string,
  creating_subscription: string,
  app_authentication_required: string,
  card_authentication_required: string,
  device_authentication_required: string,
  sms_authentication_required: string,
  email_authentication_required: string,
  captcha_authentication_required: string,
  on_error: string,
  opened: string,
};

const fintocEventGTMLabels: FintocEventGTMLabels = {
  on_available_institutions: 'SELECCIONAR INSTITUCION',
  on_authentication_form: 'INICIAR SESIÓN EN BANCO',
  creating_link: 'CREAR LINK DE INICIO DE SESIÓN',
  selecting_account: 'SELECCIÓN DE CUENTA',
  creating_subscription: 'CREANDO SUSCRIPCION',
  app_authentication_required: 'AUTENTICACION MEDIANTE APP',
  card_authentication_required: 'AUTENTICACION MEDIANTE TARJETA COORDENADAS',
  device_authentication_required: 'AUTENTICACION MEDIANTE DISPOSITIVO',
  sms_authentication_required: 'AUTENTICACION MEDIANTE SMS',
  email_authentication_required: 'AUTENTICACIÓN MEDIANTE EMAIL',
  captcha_authentication_required: 'AUTENTICACIÓN MEDIANTE CAPTCHA',
  on_error: 'ERROR CONEXION FINTOC',
  opened: 'BIENVENIDA',
  validating_second_factor: 'VALIDANDO SEGUNDO FACTOR DE AUTENTICACION',
  subscription_created: 'PAC CONFIGURADO EXITOSAMENTE',
  on_terms_and_conditions: 'VISITANDO TERMINOS Y CONDICIONES FINTOC',
  on_request_bank: 'SOLICITANDO NUEVA INTEGRACION CON BANCO',
  subscription_aborted: 'INTENTO SUSCRIPCION ABORTADA',
};

const getFintocGTMEventLabel = (value:string) => {
  if (fintocEventGTMLabels[value]) {
    return fintocEventGTMLabels[value];
  }
  return undefined;
};

type FintocWidgetProps = {
    idNumber: string,
    token: string,
}

const notSubscribedStates = ['INTENT', 'CANCELED'];
export default function FintocWidget(props: FintocWidgetProps) {
  const {
    idNumber,
    token,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [isAlreadySubscribed, setIsAlreadySubscribed] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [widgetToken, setWidgetToken] = React.useState<string|undefined>(undefined);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('¡Pago (PAC) configurado correctamente!');

  const getWidgetToken = async () => {
    setError(false);
    setOpenAlert(false);
    setLoading(true);
    try {
      const res = await postFintocSubscription(token);
      setLoading(false);
      if (!notSubscribedStates.includes(res.subscriptionStatus)) {
        setIsAlreadySubscribed(true);
        analytics.page('PU - PAGOS', 'PAC YA CONFIGURADO');
        return true;
      }
      analytics.page('PU - PAGOS', 'CONFIGURACION PAC');
      return setWidgetToken(res.widgetToken);
    } catch (e) {
      setError(true);
      setLoading(false);
      return setOpenAlert(true);
    }
  };
  let secondFactor: string | null = null;
  const getSubscriptionState = async () => {
    setError(false);
    setOpenAlert(false);
    setLoading(true);
    try {
      const res = await getFintocSubscription(token);
      if (!notSubscribedStates.includes(res.subscriptionStatus)) {
        setLoading(false);
        return setIsAlreadySubscribed(true);
      }
      return setTimeout(async () => {
        await getSubscriptionState();
      }, 2000);
    } catch (e) {
      setError(true);
      return setOpenAlert(true);
    }
  };

  const options = {
    holderType: 'individual',
    product: 'subscriptions',
    publicKey: process.env.REACT_APP_FINTOC_PUBLIC_KEY,
    onExit: async () => {
      setSuccessMessage('Si no configuras PAC, no se te podrá transferir tu producto');
      setError(true);
      setOpenAlert(true);
      await getWidgetToken();
    },
    onSuccess: async (event: {subscription: {account: {institution: { name: string}}}}) => {
      setSuccessMessage('¡PAC configurado correctamente!');
      setOpenAlert(true);
      analytics.track('Click Boton', {
        initiator: 'usuario',
        text: 'Terminar',
        bank: event.subscription.account.institution.name,
      });
      await getSubscriptionState();
    },
    username: ['dev', 'beta', 'localhost'].some((env) => process.env.REACT_APP_API_URL?.includes(env)) ? undefined : idNumber,
    onEvent: async (event: string) => {
      if (event === 'on_error') {
        analytics.page('PU - PAGOS', getFintocGTMEventLabel(event));
        return null;
      }
      if (event === 'closed') {
        analytics.track('Cierre Dialog', {
          initiator: 'usuario',
        });
        return null;
      }
      if (event.includes('authentication_required')) {
        const sf = event.split('_')[0];
        secondFactor = sf;
      }
      if (event === 'subscription_created') {
        analytics.track('PAC Suscrito', {
          initiator: 'usuario',
          authenticationMethod: secondFactor,
        });
      }
      analytics.page('PU - PAGOS', getFintocGTMEventLabel(event));
      return null;
    },
  };
  const openWidget = async () => {
    analytics.track('Click Boton', {
      text: 'ASOCIAR PAC',
      initiator: 'usuario',
    });
    const Fintoc = await getFintoc();
    const widget = Fintoc?.create({ ...options, widgetToken });
    return widget?.open();
  };

  React.useEffect(() => {
    getWidgetToken();
  }, []);

  return (
    <Card
      style={{
        borderRadius: '20px',
      }}
    >
      <div style={{ marginBottom: 10, textAlign: 'center', marginTop: 20 }}>
        <object
          className={classes.fintocLogo}
          data={fintocLogo}
          aria-labelledby="fintoc logo"
        />
      </div>
      <Divider />
      <div className={classes.content}>
        {!isAlreadySubscribed ? (
          <div style={{ margin: 20 }}>
            <Typography variant="body2" align="center" gutterBottom>
              Estimado usuario, para acceder a nuestros productos de financiamiento es necesario
              {' '}
              <b style={{ color: theme.palette.secondary.main }}>
                suscribir un PAC (Pago Automático de Cuentas)
              </b>
              {' '}
              para recibir tu crédito.
            </Typography>
            <Typography variant="body2" align="center">
              Debes tener a mano tus credenciales bancarias
              (e.g. tarjeta de coordenadas o digipass). Haz click en
              {' '}
              <b style={{ color: theme.palette.secondary.main }}>Asociar PAC</b>
              {' '}
              para comenzar el proceso.
            </Typography>
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              <Button
                disabled={loading}
                onClick={openWidget}
                variant="contained"
                color="primary"
                style={{ marginTop: '20px' }}
              >
                Asociar PAC
              </Button>
            </div>
          </div>
        ) : (
          <div style={{ margin: 20 }}>
            <Typography variant="body2" align="center" gutterBottom>
              Estimado usuario,
              {' '}
              <b style={{ color: theme.palette.secondary.main }}>
                ¡ya tienes tu PAC configurado!
              </b>
            </Typography>
            <Typography variant="body2" align="center">
              Ya estas listo por ahora
              {' '}
              <b style={{ color: theme.palette.secondary.main }}>
                ¡solo queda seguir disfrutando nuestros productos!
              </b>
            </Typography>
          </div>
        )}
        <BancameAlert
          successMessage={successMessage}
          errorMessage="Ha ocurrido un error, por favor contáctanos"
          openAlert={openAlert}
          error={error}
        />
        <Backdrop className={classes.backdrop} open={loading}>
          <div>
            <CircularProgress
              color="inherit"
            />
          </div>
          <b style={{ marginLeft: 20 }}>
            Cargando, espera un momento...
          </b>
        </Backdrop>
      </div>
    </Card>
  );
}
