import React from 'react';
import { Typography } from '@mui/material';

export const zubaleFormInputs = {
  workStatus: {
    title: 'Adicionalmente a Zubale, ¿cuál es tu situación laboral actual?',
    inputs: [
      { label: 'Empleado de medio tiempo con beneficios de ley', value: 'part-time' },
      { label: 'Empleado de tiempo completo con beneficios de ley', value: 'full-time' },
      { label: 'Trabajador independiente (adicional a Zubale)', value: 'independent-contractor' },
      { label: 'Este es mi único trabajo', value: 'zubale-only' },
      { label: 'Estudiante', value: 'student' },
      { label: 'Retirado o pensionado', value: 'retired' },
      { label: 'Otro', value: 'other' },
    ],
  },
  educationLevel: {
    title: '¿Cuál es tu nivel educacional?',
    inputs: [
      { label: 'Educación básica', value: 'middleschool' },
      { label: 'Educación media', value: 'highschool' },
      { label: 'Educación ténica superior incompleta', value: 'techincal-incomplete' },
      { label: 'Educación ténica superior completa', value: 'techincal' },
      { label: 'Educación universitaria incompleta', value: 'universitarian-incomplete' },
      { label: 'Educación universitaria completa', value: 'universitarian' },
    ],
  },
  gigworkerMotivation: {
    title: 'En un principio, ¿qué te motivó a trabajar en aplicaciones?',
    inputs: [
      { label: 'Perdí mi trabajo', value: 'unemployed' },
      { label: 'Para obtener ingresos adicionales', value: 'additional-income' },
      { label: 'Para obtener ingresos para una meta específica', value: 'specific-goal' },
      { label: 'No tengo acceso a un trabajo formal', value: 'unavailable-formal-job' },
      { label: 'Tengo más y mejores beneficios que en otros trabajos', value: 'working-conditions' },
      { label: 'Otra razón', value: 'other' },
    ],
  },
  houseIncome: {
    title: '¿Cómo se caracterizan los ingresos de tu hogar?',
    inputs: [
      { label: 'Las comparto con otro miembro de mi familia', value: 'shared' },
      { label: 'Soy el principal proveedor financiero en mi hogar', value: 'main-provider' },
      { label: 'Soy el único proveedor financiero de mi hogar', value: 'unique-provider' },
      { label: 'Uso mis ingresos para mis gastos personales', value: 'personal-use' },
    ],
  },
  economicDependents: {
    title: '¿Cuántos dependientes economicos tienes?',
    inputs: [{ label: 'Ninguno', value: '0' },
      { label: '1 (Uno)', value: '1' },
      { label: '2 (Dos)', value: '2' },
      { label: '3 (Tres)', value: '3' },
      { label: '4 o más', value: '4+' },
    ],
  },
  civilStatus: {
    title: '¿Cúal es tu estado civil? ',
    inputs: [
      { label: 'Soltero/a', value: 'single' },
      { label: 'Unión civil', value: 'civil-union' },
      { label: 'Casado/a', value: 'married' },
      { label: 'Divorciado/a', value: 'divorced' },
      { label: 'Viudo/a', value: 'widowed' },
    ],
  },
  loanPurpose: {
    title: '¿Cúal es el propósito de tu solicitud? ',
    inputs: [
      { label: 'Arreglos de la casa', value: 'homeRepairs' },
      { label: 'Colegiatura', value: 'schoolDebt' },
      { label: 'Consolidación de deuda o prepago deuda', value: 'debt' },
      { label: 'Pago tarjeta de crédito', value: 'creditCard' },
      { label: 'Vacaciones', value: 'holidays' },
      { label: 'Salud', value: 'health' },
      { label: 'Educación', value: 'education' },
      { label: 'Compras', value: 'shopping' },
      { label: 'Automóvil / Motocicleta', value: 'vehicle' },
      { label: 'Otro', value: 'other' },
    ],
  },
};
export const termsAndCondtions = (
  <Typography color="text.secondary" align="justify" style={{ maxWidth: 400, margin: 'auto' }}>
    Al hacer clic en
    {' '}
    <b>Continuar luego de ingresar tu RUT y validar el captcha,</b>
    {' '}
    aceptas que por medio del presente, en tu carácter de Zubalero,
    otorgas tu consentimiento para que Zubale proporcione los datos personales
    que estime convenientes en favor de banca.me con la finalidad exclusiva de
    utilizar los datos para la un análisis de crédito y celebración de los
    Créditos Personales que banca.me celebre con los Zubaleros. Entre otros usos,
    dicha información podrá ser utilizada para la realización de encuestas, la
    creación e implementación de procesos analíticos y estadísticos necesarios
    para realizar dichas operaciones; la promoción de productos y servicios
    relacionados; la atención de requerimientos de cualquier autoridad competente;
    la realización de cualquier actividad complementaria o auxiliar necesaria para
    la realización de los fines anteriores; la realización de consultas,
    investigaciones y revisiones en relación a cualquier queja o reclamación;
    y la puesta en contacto con Zubale para tratar cualquier tema relacionado
    con sus datos personales, en el entendido de el tratamiento, uso y
    protección de los datos personales obtenidos se hará de conformidad
    con la legislación aplicable.
  </Typography>
);
