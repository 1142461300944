import React from 'react';
import { Typography } from '@mui/material';
import type { FormSchemaV3 } from '../../../../../../../types/preloan/v3';

type HeaderProps ={
    formSchema: FormSchemaV3,
}

export default function Header(props:HeaderProps) {
  const { formSchema } = props;
  return (
    <>
      <Typography variant="h6" align="center" style={{ fontWeight: 'bold' }} gutterBottom>
        ¡Lo sentimos!
      </Typography>
      <Typography gutterBottom align="center" variant="body2">
        {formSchema.partnerProducts.some((product) => product === 'mortgage')
          ? 'Lamentablemente no cumples con los requisitos bancarios para optar a un crédito hipotecario'
          : 'Lamentablemente no cumples con los requisitos para tomar un producto con banca.me.'}
      </Typography>
    </>
  );
}
