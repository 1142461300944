import React from 'react';
import {
  Backdrop,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  SvgIconTypeMap,
  Typography,
  useTheme,
} from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import useStyles from './styles';
import { CloseAlertDialog } from './helpers';

export default function MobileNavigation(props: { showableTabs: {
    label: string,
    url: string, mobileIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string; } }[] }) {
  const { showableTabs } = props;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const handleOpenBackDrop = () => setOpenBackdrop(true);
  const handleCloseBackDrop = () => setOpenBackdrop(false);

  return (
    <>
      <SpeedDial
        ariaLabel="SpeedDial"
        color="text.secondary"
        className={classes.floatingButton}
        onClose={handleCloseBackDrop}
        onOpen={handleOpenBackDrop}
        icon={(
          <SpeedDialIcon
            className={classes.speedDial}
            style={{ height: 18, textTransform: 'none' }}
            icon={(
              <Typography
                color="white.main"
                variant="body2"
                component="div"
              >
                Menú

              </Typography>

)}
          />
)}
        FabProps={{
          sx: {
            bgcolor: 'secondary.main',
            color: 'white',
            '&:hover': {
              bgcolor: 'primary.main',
            },
          },
        }}
      >
        <SpeedDialAction
          FabProps={{
            sx: {
              '&:hover': {
                bgcolor: 'secondary.main',
                color: 'white.main',
              },
            },
          }}
          icon={(
            <CloseAlertDialog button={(
              <LogoutIcon style={{
                paddingTop: 5,
                color: 'inherit',
              }}
              />
)}
            />
)}
          onClick={handleCloseBackDrop}
          tooltipTitle={(
            <Typography variant="body2" component="div">
              Salir
            </Typography>
)}
          tooltipOpen
          tooltipPlacement="right"
        />
        {showableTabs.map((action) => (
          <SpeedDialAction
            FabProps={{
              sx: {
                bgcolor: location.pathname === action.url
                  ? 'primary.main' : undefined,
                color: location.pathname === action.url
                  ? 'white.main' : undefined,
                '&:hover': {
                  bgcolor: 'secondary.main',
                  color: 'white.main',
                },
              },
            }}
            key={action.label}
            icon={<action.mobileIcon style={{ color: 'inherit' }} />}
            onClick={() => history.push(action.url)}
            tooltipTitle={(
              <Typography
                variant="body2"
                component="div"
                style={{
                  color: location.pathname === action.url
                    ? theme.palette.primary.main : undefined,
                  fontWeight: location.pathname === action.url
                    ? 'bold' : undefined,
                }}
              >
                {action.label}
              </Typography>
)}
            tooltipOpen
            tooltipPlacement="right"
          />
        ))}

      </SpeedDial>
      <Backdrop
        className={classes.backdrop}
        open={openBackdrop}
      />
    </>
  );
}
