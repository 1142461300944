import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    height: 200,
    placeContent: 'center',
    [theme.breakpoints.down('md')]: {
      height: '100%',
    },
  },
  fintocLogo: {
    width: 140,
    [theme.breakpoints.down('sm')]: {
      width: 100,
    },
  },
}));

export default useStyles;
