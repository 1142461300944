import React from 'react';
import { Typography } from '@mui/material';
import useOnScreen from '../../../utils/onScreenHook';

type TalanaTermsAndConditionsContentProps = {
    handleEnableButton: ()=> void;
}

export default function TalanaTermsAndConditionsContent(
  props:TalanaTermsAndConditionsContentProps,
) {
  const { handleEnableButton } = props;
  const ref = React.useRef(null);
  const isVisible = useOnScreen(ref);
  React.useEffect(() => {
    if (isVisible) {
      handleEnableButton();
    }
  }, [isVisible]);
  return (
    <div style={{ textAlign: 'center' }}>
      <Typography
        variant="h6"
        gutterBottom
        fontWeight="bold"
      >
        Antes de continuar necesitamos que leas lo siguiente

      </Typography>
      <br />
      <div
        style={{ textDecoration: 'underline' }}
      >
        TÉRMINOS Y CONDICIONES ALIANZA TALANA-BANCA.ME
      </div>

      <div style={{ textAlign: 'justify' }}>
        <p>
          Talana es un software cloud y aplicación web de recursos humanos, entregado a través de su
          sitio
          web https://talana.com y la aplicación móvil denominada Talana Next, destinado a la gestión de
          recursos humanos a través de diferentes módulos: Remuneraciones, Gestión de Personas,
          Asistencia y Turnos, Firma Digital, Comunicaciones, Desarrollo Organizacional, Centro de
          Requerimientos y Selección.
        </p>
        <p>
          Talana tiene actualmente una alianza comercial con banca.me que permitirá a los
          Usuarios de
          los
          Clientes de Talana, la posibilidad de tomar créditos de manera 100% digital y rápida, a
          través del
          software que banca.me tiene habilitado para estos efectos, lo cual implica una experiencia
          única
          frente a lo que el mercado hoy día ofrece.
        </p>
        <p>
          Los presentes Términos y Condiciones contienen información importante acerca de
          los derechos y
          obligaciones de los Usuarios para poder acceder a los beneficios de la alianza
          comercial entre
          Talana
          y banca.me., por lo que antes de realizar cualquier acción, el Usuario debe leer
          cuidadosamente los
          siguientes Términos y Condiciones. La persona que acepta los presentes
          Términos y Condiciones
          garantiza que tiene la capacidad legal para obligarse.
        </p>
        <p>
          Si el Usuario acepta los presentes Términos y Condiciones está garantizando que los
          ha leído y,
          acepta expresamente, que Talana tratará los datos personales del Usuario que se indican a
          continuación, compartiendo los mismos con banca.me, a fin de poder acceder a los
          beneficios
          de la
          alianza comercial entre Talana y banca.me, cotizar un crédito con banca.me y acceder
          al descuento por planilla de las cuotas del crédito que se suscriba:
        </p>
        <ul>
          <li>Nombre completo.</li>
          <li>Rol Único Tributario.</li>
          <li>Sexo.</li>
          <li>Fecha de nacimiento</li>
          <li>Email personal.</li>
          <li> Celular.</li>
          <li> Tipo de contrato de trabajo que tiene.</li>
          <li>Fecha de contrato de trabajo.</li>
          <li>Cargo que ocupa.</li>
          <li> Fechas de duración del contrato de trabajo, en caso de corresponder.</li>
          <li>Últimas tres liquidaciones de sueldo.</li>
        </ul>
        <p>
          Toda esta información será recabada por banca.me de Talana a través de una API configurada
          especialmente para estos efectos.
        </p>
        <p>
          Adicionalmente, Talana en ningún caso estará obligada a entregar a banca.me información
          referida
          a posibles avales ni ninguna otra información personal del Usuario diferente a la ya
          estipulada
          anteriormente y que es la autorizada expresamente por el Usuario por los presente
          Términos y
          Condiciones.
        </p>
        <p>
          El Usuario se compromete a proporcionar información veraz, completa y actualizada.
          A su vez, es
          responsable de todo lo que se haga desde su cuenta a través de cualquier dispositivo.
        </p>
        <p ref={ref}>
          Una vez que el Usuario ha contratado un crédito con banca.me, la relaciones entre ellos
          se regirán
          por el contrato que los une, no teniendo Talana ninguna participación o injerencia en las
          cuotas de
          los créditos, los montos de los mismos y/o los potenciales descuentos que se realicen
          de las
          liquidaciones de sueldo del Usuario para el pago del mismo.
        </p>
      </div>
    </div>

  );
}
