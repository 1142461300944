import React from 'react';
import AlertDialog from '../../../components/AlertDialog';
import { termsAndCondtions } from './inputsData';

type TermsAndConditionsDialogProps ={
    button: React.ReactElement
}

export default function TermsAndConditionsDialog(props: TermsAndConditionsDialogProps) {
  const { button } = props;
  return (
    <AlertDialog
      dialogTitle="Términos y condiciones convenio banca.me con Zubale"
      button={button}
      hideSubmitButton
      dialogContent={termsAndCondtions}
    />
  );
}
