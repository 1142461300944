import React from 'react';
import { Link, Typography, useTheme } from '@mui/material';
import { FormSchemaV3, PreApproveType } from '../../../../../../../types/preloan/v3';
import PreApproveCard from '../../../../../../../components/PreApprove/PreApproveCard';
import { PartnerConfiguration } from '../../../../../../../components/LeadFormIndex/types';

export const getContactMessage = (partnerConfiguration: PartnerConfiguration) => {
  let contactMessage = ' ';
  if (partnerConfiguration.contactEmail) {
    contactMessage += `puedes escribirnos a ${partnerConfiguration.contactEmail}`;
    if (partnerConfiguration.contactPhone) {
      contactMessage += ' o ';
    }
  }
  if (partnerConfiguration.contactPhone) {
    contactMessage += `puedes llamar al ${partnerConfiguration.contactPhone}`;
  }
  return contactMessage;
};

export const getRedirectUrl = (
  partnerConfiguration: PartnerConfiguration,
  success: boolean,
  idNumber: string,
) => {
  if (partnerConfiguration.legalName === 'Brincus') {
    const url = window.location.host;
    if ((url.includes('dev') || url.includes('localhost') || url.includes('beta'))) {
      return success
        ? `https://www.brincus.com/dev/cuenta/bancame_exito?rut=${idNumber}`
        : `https://www.brincus.com/dev/cuenta/bancame_rechazo?rut=${idNumber}`;
    }
    return success
      ? `https://home.brincus.com/cuenta/bancame_exito?rut=${idNumber}`
      : `https://home.brincus.com/cuenta/bancame_rechazo?rut=${idNumber}`;
  }
  if (!partnerConfiguration.callbacks) {
    return partnerConfiguration.website || 'https://banca.me';
  }
  if (success) {
    return partnerConfiguration.callbacks.success || (partnerConfiguration.website || 'https://banca.me');
  }
  return partnerConfiguration.callbacks.failed || (partnerConfiguration.website || 'https://banca.me');
};

export const firstMessageSwitch = (step: FormSchemaV3['step'], preApprove: PreApproveType, origin: FormSchemaV3['origin'], partner: FormSchemaV3['partner']) => {
  const theme = useTheme();
  switch (step) {
    case 'WAITING_COMPANY_AFFILIATION':
      return (
        <b>
          Ahora nos pondremos en contacto con tu empleador.
          <span
            style={{ color: theme.palette.secondary.main }}
          >
            Si tu empresa decide unirse al convenio banca.me

          </span>
          {' '}
          , podrás tomar tu crédito con descuento directo de tu sueldo.
        </b>
      );
    case 'READY_FOR_LOAN':
      return (
        <>
          <Typography
            component="div"
            style={{ color: theme.palette.secondary.main }}
            gutterBottom
          >
            {preApprove?.productType === 'mortgage' || origin === 'IRentup' || origin === 'goplaceit' || partner === 'Portal Inmobiliario' ? '' : '¡Tu solicitud ha sido recibida exitosamente!'}
          </Typography>
          {preApprove?.productType !== 'mortgage' || origin === 'IRentup' || origin === 'goplaceit' || partner === 'Portal Inmobiliario'
            ? (
              <>
                <Typography
                  component="div"
                  gutterBottom
                >
                  Ahora evaluaremos tu caso y tendrás una respuesta a la brevedad.
                  {' '}
                </Typography>
                <br />
                <div>
                  <b>Te contactaremos lo antes posible</b>
                </div>
              </>
            )
            : (
              <>
                {origin === 'norteVerde' ? (
                  <Typography
                    component="div"
                    gutterBottom
                  >
                    Serás contactado por un ejecutivo de Norte Verde para continuar
                    con tu proceso de compra.
                  </Typography>
                ) : (
                  <Typography
                    component="div"
                    gutterBottom
                  >
                    ¡Felicidades! Según nuestros registros,
                    tu perfil califica para un crédito hipotecario:
                    <PreApproveCard preApprove={preApprove} />
                    {' '}
                  </Typography>
                )}
                <Typography
                  style={{ marginTop: 20 }}
                  variant="caption"
                  align="center"
                  component="div"
                  gutterBottom
                >
                  banca.me no otorga créditos hipotecarios.
                  {' '}
                  <Link style={{ textDecoration: 'underline' }} href="https://www.cmfchile.cl/educa/621/w3-propertyvalue-45015.html#:~:text=Los%20cr%C3%A9ditos%20hipotecarios%20pueden%20ser,ahorro%20y%20cr%C3%A9dito%20y%20mutuarias." target="_blank" rel="noopener">
                    {' '}
                    Solo instituciones reguladas por la CMF pueden hacerlo.
                  </Link>
                  {' '}
                  Esta simulación es referencial y
                  no representa una pre-aprobación de crédito hipotecario.
                </Typography>
              </>
            )}
        </>
      );

    case 'COMPANY_APROVE':
      return (
        <b>
          Con la información provista podremos
          {' '}
          <span
            style={{ color: theme.palette.secondary.main }}
          >
            atenderte y entregarte una evaluación

          </span>
          {' '}
          lo antes posible.
        </b>
      );
    case 'ARCHIVED_SUCCESS':
      return 'Según nuestros registros tu perfil se ha calificado y archivado como exitoso';
    default:
      return 'Te contactaremos pronto';
  }
};
export const secondMessageSwitch = (step: FormSchemaV3['step']) => {
  switch (step) {
    case 'WAITING_COMPANY_AFFILIATION':
      return 'Si han pasado más de 5 días hábiles y no te hemos contactado,';
    case 'READY_FOR_LOAN':
      return 'Si tienes una duda o pregunta,';
    case 'COMPANY_APROVE':
      return 'Si tienes alguna inquietud,';
    case 'ARCHIVED_SUCCESS':
      return 'Si no te ha llegado información sobre esto,';
    case 'MANUAL_VERIFICATION':
      return 'Si tienes alguna duda,';
    default:
      return 'Si no te hemos contactado,';
  }
};
