import React from 'react';
import {
  List,
  ListItem,
  Divider,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Avatar,
  Typography,
  useTheme,
} from '@mui/material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { useHistory } from 'react-router-dom';
import ViewTitle from '../../../components/ViewTitle';
import { sx } from './styles';
import type { ProductsListProps } from './types';
import analytics from '../../../utils/analytics';

const productTypeLabels: {[key:string]:{label: string, icon: null|React.ReactElement}} = {
  'user-report': {
    label: 'Informe de deudas',
    icon: <SummarizeIcon style={{ color: 'white' }} />,
  },
};

const getProductTypeLabels = (type: string) => {
  if (Object.keys(productTypeLabels).includes(type)) {
    return productTypeLabels[type];
  }
  return { label: type, icon: null };
};

const renderScore = (type: string, score: number) => {
  switch (type) {
    case 'user-report':
      return (
        <div>
          <Typography
            variant="h4"
            color="secondary"
            align="center"
          >
            {score}
          </Typography>
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >
            Score banca.me
          </Typography>
        </div>
      );
    default:
      return null;
  }
};

export default function BoughtProducts(props:ProductsListProps) {
  const { userReportsData } = props;
  const theme = useTheme();
  const history = useHistory();
  return (
    <>
      <ViewTitle
        title="Productos comprados"
        color="alternativePrimary"
        variant="body1"
        disablePadding
      />
      <List
        sx={sx().list}
        component="div"
      >
        {userReportsData.reports.length > 0 && userReportsData.reports.map((userReport) => (
          <div key={userReport.id}>
            <ListItemButton onClick={() => {
              analytics.track('Navegación', { accion: 'Ver informe de deudas' });
              history.push(`/account/products/user-report/${userReport.id}`);
            }}
            >
              <ListItem alignItems="flex-start">
                <ListItemAvatar style={{ marginTop: '4px' }}>
                  <Avatar alt="Product avatar" style={{ backgroundColor: theme.palette.primary.main }}>
                    {getProductTypeLabels(userReport.type).icon}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<b>{getProductTypeLabels(userReport.type).label}</b>}
                  secondary={(
                    <Typography variant="caption" component="div" color="text.secondary">
                      {new Date(userReport.createdAt).toLocaleDateString('es-CL')}
                    </Typography>
          )}
                />
                {renderScore(userReport.type, userReport.score.score)}

              </ListItem>
            </ListItemButton>
            <Divider />
          </div>
        ))}
        {userReportsData.reports.length === 0
                  && (
                    <>
                      <Typography
                        align="center"
                        fontStyle="italic"
                        marginTop={5}
                        color="text.secondary"
                      >
                        (No hay elementos para mostrar)
                      </Typography>
                      <Typography
                        align="center"
                        fontStyle="italic"
                        color="text.secondary"
                        maxWidth={250}
                        margin="10px auto"
                      >
                        Aquí aparecerán los productos que hayas comprado en banca.me
                      </Typography>
                      <Divider />
                    </>
                  )}
      </List>
    </>
  );
}
