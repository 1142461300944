import {
  Checkbox, FormControlLabel, TextField, Tooltip, Typography,
} from '@mui/material';
import React from 'react';
import { prettifyRut } from 'react-rut-formatter';
import useStyles from './styles';
import AlertDialog from '../../../../../../../../components/AlertDialog';
import numeroDocumento from '../../../../../../../../assets/numero_documento.webp';
import { termsAndConditions } from './helpers';
import { ExperianChallengeProps } from './types';
import analytics from '../../../../../../../../utils/analytics';

export default function ExperianChallengeRut(props: ExperianChallengeProps) {
  const {
    values, handleSetDisableContinue, loading, handleChange, error,
  } = props;
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    handleSetDisableContinue(!checked || !values.serialNumber);
  }, [checked, values.serialNumber]);

  React.useEffect(() => {
    analytics.page('PLR - SITUACION LABORAL', 'INGRESO DATOS EXPERIAN');
  }, []);
  const handleClickWhereIsSerialNumber = () => {
    analytics.track('Click Link', {
      text: '¿Qué es el número de documento/serie?',
      initiator: 'usuario',
    });
  };
  const handleCheckTermsAndConditions = () => {
    analytics.track('Click Checkbox', {
      text: 'Acepto las condiciones de uso del servicio',
      initiator: 'usuario',
    });
    setChecked((o) => !o);
  };
  const handleHoverTermsAndConditions = () => {
    analytics.track('Hover Link', {
      text: 'condiciones de uso del servicio',
      initiator: 'usuario',
    });
  };

  return (
    <div>
      <Typography
        gutterBottom
        align="center"
        component="div"
      >
        Para consultar tus datos en Previred, necesitamos que nos indiques
        el número de documento / serie
        de tu cédula de identidad

      </Typography>
      <br />
      <Typography
        color="textSecondary"
        gutterBottom
        component="div"
      >
        RUT
      </Typography>
      <TextField
        style={{ marginBottom: 10 }}
        value={prettifyRut(values.rut)}
        disabled
        variant="outlined"
      />

      <Typography
        color="secondary"
        component="div"
      >
        N° de documento / serie
      </Typography>
      <AlertDialog
        maxWidth="lg"
        dialogTitle={<Typography fontWeight="bold">¿Qué es el número de documento / serie?</Typography>}
        hideSubmitButton
        dialogContent={(
          <>
            <img
              style={{ borderRadius: 30 }}
              width={600}
              src={numeroDocumento}
              alt="Número de documento/serie"
            />
            <Typography component="div" variant="caption">Fuente: Registro Civil de Chile</Typography>
          </>
)}
        button={(
          <Typography
            gutterBottom
            onClick={handleClickWhereIsSerialNumber}
            variant="caption"
            className={classes.whereIsSerialNumber}
            component="div"
          >
            ¿Qué es el número de documento / serie?
          </Typography>
        )}
      />
      <TextField
        id="serialNumber"
        name="serialNumber"
        style={{ marginBottom: 10 }}
        variant="outlined"
        placeholder="e.j.: 100100001"
        value={values.serialNumber}
        onChange={handleChange}
        disabled={loading}
      />
      <FormControlLabel
        control={(
          <Checkbox
            size="small"
            checked={checked}
            onClick={handleCheckTermsAndConditions}
            disabled={loading}
          />
            )}
        label={(
          <Typography display="inline" component="div">
            Acepto las
            {' '}
            <Tooltip
              onMouseEnter={handleHoverTermsAndConditions}
              title={termsAndConditions}
              aria-label="Términos y condiciones Experian"
            >
              <Typography
                display="inline"
                style={{ textDecoration: 'underline' }}
                component="div"
              >
                condiciones de uso del servicio

              </Typography>
            </Tooltip>
          </Typography>
)}
      />
      {error && (
        <Typography
          color="error"
          component="div"
          fontWeight="bold"
        >
          Ha ocurrido un error, por favor contáctanos
        </Typography>
      )}
    </div>
  );
}
